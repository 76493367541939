/* SCROLLS BEHAVIOR FOR WHOLE PAGE */

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

/* NAV BAR */

.navBar {
  position: fixed;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  top: 0;
  width: 100%;
  z-index: 2000;
  font-family: 'Questrial', sans-serif;
  text-decoration: none;
  text-align: center;
  border-bottom: solid;
  border-width: 1px;
  border-color: rgb(136, 136, 136);
  transition: all .3s ease-in-out;
}

.navBar ul {
  justify-content: space-evenly;
  font-size: large;
  display: flex;
  width: 70%;
  margin: auto;
  padding: 1%;
  transition: all 0.5s ease-in-out;
}

.navBar a {
  transition: all 0.5s ease-in-out;
}

a {
  color: darkgray;
}

a:visited {
  color: darkgray;
}

a.glow, a.glow:focus {
	text-decoration: none;
	color:white;
	text-shadow: none;
	-webkit-transition: 500ms linear 0s;
	-moz-transition: 500ms linear 0s;
	-o-transition: 500ms linear 0s;
	transition: 500ms linear 0s;
	outline: 0 none;
}

a.glow:hover, a.glow:active, a.glow:focus {
  text-shadow: 0px 0px 4px rgb(255, 255, 255);
}

/* THIS APPLIES TO BOTH NAV BAR AND PROJECT CARDS */

ul {
  list-style-type: none;
  text-decoration: none;
  padding-inline-start: 0px
}

/* PAGE TITLES */

h2 {
  color: whitesmoke;
  text-align: center;
  font-size: xx-large;
  padding-top: 10%;
  padding-bottom: 2%;
}

/* HOME PAGE */

.homeInfoCard {
  display: flex;
  text-align: center;
  justify-content: center;
  font-family: 'Questrial', sans-serif;
  background: rgb(16, 2, 41);
  color: whitesmoke;
}

.homePageList {
  margin-bottom: 28%;
}

.homePageList li:first-child {
  font-size: 8vh;
  margin-top: 30vh;
}

.homePageList li:last-child {
  font-size: 2.5vh;
  margin-top: 5vh;
}

/* ABOUT ME */

.aboutMe {
  background: rgb(16, 2, 41);
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: white;
  padding: 1%;
}

.aboutMeLeft {
  float: left;
  width: 30%
}

.aboutMeLeft img {
  height: 88%;
  width: 75%;
  border-radius: 15px;
  padding-top: 0%;
  margin-top: 6%;
  box-shadow: 0px 0px 20px rgb(0, 0, 0);
}

.aboutMeText {
  width: 70%;
  text-align: left;
  display: inline-block;
  padding-top: 2%;
  padding-right: 2%;
}

.aboutMe p:first-of-type {
  margin-top: -5px;
  font-size: larger;
  color: whitesmoke;
}

/* SKILLS PAGE */

.skillsPage {
  background:  rgb(16, 2, 41);
  text-align: center;
  font-family: 'Questrial', sans-serif;
  color: white;
}

.development-icons{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  padding-top: 0.5em;
  width: 70%;
}

@keyframes bounce {
  0%   { transform: translateY(0); }
  30%  { transform: translateY(-20px); }
  50%  { transform: translateY(0); }
  100% { transform: translateY(0); }
}

.individual-icons:hover {
  animation: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.development-icons i{
  width: 1.8em;
  font-size: 4em;
  padding: 0.3em;
  margin: 0 auto;
}

.development-icons p {
  margin: 0.5em auto;
  font-size: 1em;
}

.development-icons div {
  margin: 1.2em;
}

/* PROJECT PAGE */

.projectPage {
  text-align: center;
  font-family: 'Questrial', sans-serif;
  color: whitesmoke;
  overflow: auto;
  padding-bottom: 2%;
  background:  rgb(16, 2, 41)
}

.wrapper {
  background-color:rgba(119, 118, 118, .4);
  display: flex;
  width: 70%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  box-shadow: 1px 1px 5px rgb(119, 118, 118);
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  border-color: rgb(119, 118, 118, .4);
  overflow: auto;
  transition: 0.5s ease-in-out;
}

.wrapper:hover {
  box-shadow: 0px 0px 12px rgb(221, 221, 221);
}

.project-left {
  float: left;
  width: 30%;
  padding-top: 1.5%;
  transition: all .8s ease-in-out;
}

.project-left img {
  width: 90%;
  height: 90%;
  border-radius: 5px;
  margin-left: 1%;
  margin-top: 1%;
  box-shadow: 0px 0px 10px rgb(0, 0, 0)
}

.project-left img:hover {
  box-shadow: 0px 0px 15px black;
}

.project-right {
  float: right;
  width: 60%;
  padding-left: 2%;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.projectList {
  overflow: auto;
  margin: auto;
}

.tech-list {
  display: flex;
  flex-direction: row;
  text-align: left;
  overflow: auto;
}

.tech-list li {
  margin-right: 3%;
  font-weight: bold;
}

.project-right svg {
  fill: black;
  margin-right: 0.6em;
  margin-top: 3px;
  vertical-align: text-bottom;
}

/* CONTACT PAGE */

.contactMePage {
  width: 100%;
  margin: auto;
  margin-top: -2%;
  text-align: center;
  font-family: 'Questrial', sans-serif;
  background:  rgb(16, 2, 41);
}

.contactMePage ul {
  display: flex;
  flex-direction: row;
  width: 60%;
  margin: auto;
  margin-bottom: -2%;
  padding-bottom: 8%;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.contactMePage img {
  fill: white;
  color: white;
  height: 95%;
  width: 80%;
  background-color: rgb(219, 219, 219);
  border: solid;
  border-radius: 70px;
  border-width: 1px;
  border-color: whitesmoke;
  box-shadow: 2px 2px 80px rgb(233, 233, 233);
}

/* FOOTER */

.footer {
  color: whitesmoke;
  background-color:  rgb(16, 2, 41);
  height: 3%;
  text-align: center;
  font-family: 'Questrial', sans-serif;
  left:0;
  bottom:0;
  right:0;
  border-top: solid;
  clear: both;
  border-color: rgb(136,136,136);
  border-width: 1px;
}

.footer p:first-child {
  padding-bottom: 2%;
  margin-bottom: -2%;
}

/* MEDIA QUERIES, MOBILE CSS */

@media only screen and (max-width: 768px) {
  
  .navBar {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .navBar li {
    padding: 4%;
    margin-left: 1%;
    margin-right: 1%;
  }

  .homePageList {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  /* ABOUT ME */
  .aboutMeText {
    font-size: 1.7vh;
    padding: 1vh;
    margin: auto;
    width: 95%;
    margin-top: 2%;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .aboutMe {
    padding-top: 40%;
  }

  .wrapper {
    width: 90%;
    flex-direction: column;
    margin: auto;
    margin-top: 5%;
  }

  .aboutMeLeft {
    width: 80%;
    margin: auto;
    margin-top: 3%;
  }

  .aboutMeLeft img {
    height: 10%;
    margin: auto;
  }

  /* SKILLS PAGE */
  .skillsPage {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .development-icons {
    width: 100%;
  }

  .individual-icons {
    width: 35%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .development-icons p {
    font-size: 1.7vh;
  }

  /* PROJECTS PAGE */
  .projectPage {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: auto;
  }

  .project-left {
    margin: auto;
    width: 95%;
  }

  .project-left img {
    margin-top: 3%;
    height: 70%;
    width: 70%;
  }

  .project-right {
    font-size: 1.7vh;
    margin: auto;
    text-align: center;
    width: 95%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .tech-list {
    text-align: center;
    justify-content: center;
  }

  /* CONTACT ME PAGE */
  .contactMePage ul {
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .contactMePage img {
    height: 100%;
    width: 60%;
  }

  /* FOOTER */
  .footer {
    font-size: 1.8vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 2%;
    padding-bottom: 5%;
  }

}